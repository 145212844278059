import React from "react";
import BaseLayout from "../components/baseLayout";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import HeroTitle from "../components/heroTitle";

export default function ProcessTool({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <BaseLayout>
      <HeroTitle title={frontmatter.name} />
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-4-tablet">
              <GatsbyImage
                image={frontmatter.img.childImageSharp.gatsbyImageData}
                alt={frontmatter.name}
              />
            </div>
            <div className="column content has-text-justified">
              <h3 className="title">{frontmatter.description}</h3>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        name
        description
        path
        img {
          childImageSharp {
            gatsbyImageData(width: 350, height: 350)
          }
        }
      }
    }
  }
`;
